import config from '@/config/config'
export default {
  LIVE: {
    value: '1',
    label: '直播',
    show: !window.uploadUrl,
    icon: '#icon-icon_zhibo-xian'
  },
  VIDEO: {
    value: '2',
    label: '视频',
    show: true,
    icon: '#icon-icon_shipin-xian'
  },
  DATUM: {
    value: '3',
    label: '资料',
    show: true,
    icon: '#icon-ziliaoshenqing_huaban_huaban'
  }
}
