<template>
  <div class="table-container">
    <div class="opt-btn-container">
      <Button class="filter-btn btn-shadow" size="large" type="primary" @click="addMemberModalVisible = true">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-xinzeng"></use>
        </svg>
        添加学员
      </Button>
      <Button
        class="filter-btn btn-shadow"
        size="large"
        type="primary"
        @click="handleDeleteCourseMembers(selectedMemberIds)"
        :disabled="selectedMemberIds.length === 0"
      >
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-shanchu"></use>
        </svg>
        删除
      </Button>
    </div>
    <Table
      class="flex-table"
      stripe
      :columns="tableHeader"
      :data="members.data || []"
      :loading="loading"
      @on-selection-change="checkedRowChangeHandler"
    />
    <div class="page">
      <div style="float: right;">
        <Page
          show-total
          show-sizer
          show-elevator
          :total="members.total"
          :current="pageNumber + 1"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </div>

    <AddCourseMemberModal
      v-model="addMemberModalVisible"
      :course-id="courseId"
      :course-member-ids="courseMemberIds"
      @on-success="handleAddCourseMembersSuccess"
    ></AddCourseMemberModal>
    <DeleteModal v-model="deleteVisible" title="确认删除选中学员" :loading="deleteLoading" @on-ok="deleteCourseMembers">
      <span class="error">删除学员后不可恢复</span>， 您还要继续吗？
    </DeleteModal>
  </div>
</template>
<script>
import defaultAvatar from '@/assets/defaultAvatar.svg'
import { Avatar, Tooltip } from 'view-design'
import courseApi from '@api/course'
import AddCourseMemberModal from './AddCourseMemberModal'
import DeleteModal from '@components/common/DeleteModal'

export default {
  components: {
    AddCourseMemberModal,
    DeleteModal
  },
  props: {
    courseId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      tableHeader: [
        // 每列宽度没有实际确定
        {
          type: 'selection',
          align: 'center',
          className: 'selection-column'
        },
        {
          title: '',
          render: (h, data) => {
            return <Avatar src={data.row.avatar || defaultAvatar} />
          },
          className: 'avatar-column'
        },
        {
          title: '姓名',
          key: 'name',
          className: 'name-column'
        },
        {
          title: '部门',
          key: 'department',
          className: 'department-column'
        },
        {
          title: '邮箱',
          key: 'email',
          className: 'email-column'
        },
        {
          title: '手机',
          key: 'phone',
          className: 'phone-column'
        },
        {
          title: '操作',
          className: 'operation-column',
          render: (h, data) => {
            return (
              <div>
                <Tooltip content="删除" placement="top" class="operation-tooltip">
                  <svg class="icon" aria-hidden="true" onClick={() => this.handleDeleteCourseMembers([data.row.userId])}>
                    <use xlinkHref="#icon-shanchu" />
                  </svg>
                </Tooltip>
              </div>
            )
          }
        }
      ],
      loading: false,
      addMemberModalVisible: false,
      deleteVisible: false,
      deleteLoading: false,
      pageNumber: 0,
      pageSize: 10,
      members: {
        total: 0,
        data: null
      },
      selectedMemberIds: [],
      willDeleteMemberIds: [],
      courseMemberIds: []
    }
  },
  created() {
    this.fetchCourseMembers()
    this.fetchCourseMemberIds()
  },
  methods: {
    changePage(page) {
      this.pageNumber = page - 1
      this.fetchCourseMembers()
    },
    changePageSize(size) {
      this.pageSize = size
      this.fetchCourseMembers()
    },
    fetchCourseMemberIds() {
      courseApi.getCourseMemberIds(this.courseId).then(res => {
        this.courseMemberIds = res.res
      })
    },
    fetchCourseMembers() {
      this.loading = true

      return courseApi
        .getCourseMembers(this.courseId, this.pageNumber, this.pageSize)
        .then(res => {
          this.members = res.res
          this.selectedMemberIds = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    checkedRowChangeHandler(selection) {
      this.selectedMemberIds = selection.map(v => v.userId)
    },
    handleDeleteCourseMembers(data) {
      this.deleteVisible = true
      this.willDeleteMemberIds = data
    },
    deleteCourseMembers() {
      this.deleteLoading = true

      courseApi
        .deleteCourseMembers({
          courseId: this.courseId,
          students: this.willDeleteMemberIds
        })
        .then(() => {
          this.resetMembers()
          this.deleteVisible = false
        })
        .finally(() => {
          this.deleteLoading = false
        })
    },
    handleAddCourseMembersSuccess() {
      this.resetMembers()
    },
    resetMembers() {
      this.pageNumber = 0
      this.fetchCourseMembers()
      this.fetchCourseMemberIds()
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../../theme/variables';

.table-container {
  position: relative;

  .opt-btn-container {
    position: absolute;
    right: 0;
    top: -46px;

    .filter-btn {
      margin-right: 20px;
      min-width: 100px;
    }
  }

  ::v-deep .flex-table {
    .selection-column {
      width: 5%;
      max-width: 50px;
    }

    .avatar-column {
      width: 5%;
      max-width: 60px;
    }

    .name-column {
      width: 18%;
    }

    .department-column {
      width: 18%;
      flex: 1 1 auto;
    }

    .email-column {
      width: 18%;
    }

    .phone-column {
      width: 18%;
    }

    .operation-column {
      width: 18%;
      justify-content: flex-end;

      .operation-tooltip {
        margin-right: 10px;
        cursor: pointer;
        color: @primary-color;
        transition: all @default-transition;

        &:hover {
          color: @primary-hover-color;
        }
      }
    }
  }

  .page {
    flex: 0 0 auto;
    padding: 20px;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;

    .page-options {
      float: left;
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>
