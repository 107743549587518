<template>
  <div class="course-container">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>课程管理</el-breadcrumb-item>
        <el-breadcrumb-item><span @click="toCourse" class="toCourse">课程列表</span></el-breadcrumb-item>
        <el-breadcrumb-item>课程详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="course-content-wrapper">
      <div class="content">
        <div class="title" style="height: 40px">
          <div @click="toCourse" @mouseenter="showIcon = true" @mouseleave="showIcon = false" style="cursor: pointer">
            <svg v-if="showIcon === false" class="icon" aria-hidden="true" style="height: 15px;width: 15px">
              -->
              <use xlink:href="#icon-shouqi1" />
            </svg>
            <svg v-if="showIcon === true" class="icon" aria-hidden="true" style="height: 22px;width: 22px;margin-right: 10px">
              -->
              <use xlink:href="#icon-shangla-copy1-copy" />
            </svg>
          </div>
          <div @click="toFunctional" style="cursor: pointer" v-if="this.$route.query.addCourse !== undefined">
            返回上一层
          </div>
          <!--        <div v-if="course.publicity === 0 && course.status !== 0">-->
          <!--          <div v-if="course.status === 2" class="course-operation">-->
          <!--            <svg class="icon" aria-hidden="true">-->
          <!--              <use xlink:href="#icon-weikaifang"></use>-->
          <!--            </svg>-->
          <!--            <span @click="updateCoursePublicity(0)">开放课程</span>-->
          <!--          </div>-->
          <!--          <div v-else class="course-operation">-->
          <!--            <svg class="icon" aria-hidden="true">-->
          <!--              <use xlink:href="#icon-suo"></use>-->
          <!--            </svg>-->
          <!--            <span @click="updateCoursePublicity(1)">关闭课程</span>-->
          <!--          </div>-->
          <!--        </div>-->
        </div>

        <!--课程详情-->
        <div class="detail" v-loading="loading" element-loading-text="加载中...">
          <!--课程封面-->
          <div @mouseenter="hoverAvatar = true" @mouseleave="hoverAvatar = false">
            <div style="position: relative;">
              <div>
                <img :src="course.cover" alt="" style="border-radius: 3px" />
              </div>
              <div class="detailCover" v-if="course.isOwner && hoverAvatar" style="border-radius: 3px">
                <Upload
                  :show-upload-list="false"
                  :before-upload="beforeUpload"
                  :data="uploadParams"
                  :format="['jpg', 'jpeg', 'png']"
                  type="drag"
                  action="#"
                >
                  <div style="height: 200px;color: white;font-weight: bold;font-size: 16px;line-height: 200px;">
                    编辑
                    <!--                   <svg class="icon" aria-hidden="true" style="height: 150px;width: 100px;position: relative;top: 20px">-->
                    <!--                     <use xlink:href="#icon-xinzeng"></use>-->
                    <!--                   </svg>-->
                  </div>
                </Upload>
              </div>
            </div>
          </div>
          <!--右侧课程详情-->
          <div class="right-detail" style="margin-left: 20px">
            <!--          <div class="detail-item">-->
            <!--            <div class="item-label">课程标签：</div>-->
            <!--            <Tag>{{course.category}}</Tag>-->
            <!--          </div>-->
            <div class="detail-item" style="display: inline-block">
              <div class="item-label" style="display: flex;">
                <el-tooltip effect="dark" :content="course.name" placement="top" :open-delay="500">
                  <span v-if="!showUpdateName">{{ course.name }}</span>
                </el-tooltip>
                <div v-if="course.isOwner && showUpdateName">
                  <Input maxlength="30" v-model="course.name" show-word-limit style="width: 300px" />
                  <Button type="primary" style="margin-left: 12px;border-radius:15px;" @click="updateClassName">确认</Button>
                  <Button type="primary" style="margin-left: 12px;border-radius:15px;" @click="showUpdateName = false">取消</Button>
                </div>
                <div v-if="course.isOwner && !showUpdateName" @click="nameUpdate(course.name)" style="cursor: pointer">
                  <svg class="icon" style="margin-left: 12px;color: #A0A6ADFF" aria-hidden="true">
                    <use xlink:href="#icon-caozuobianji" />
                  </svg>
                </div>
              </div>
              <div style="width: 100%;max-height:70px;margin-top: 20px;color:#2B343DFF;display: flex;">
                <el-tooltip effect="dark" :content="course.description" placement="top" :open-delay="500">
                  <span v-if="!showUpdateDes" style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{ course.description }}</span>
                </el-tooltip>
                <div v-if="course.isOwner && showUpdateDes" style="width: 100%;display: flex;justify-content: space-between;">
                  <Input maxlength="200" show-word-limit type="textarea" :rows="3" v-model="course.description" />
                  <Button type="primary" style="margin-left: 12px;border-radius:15px;position: relative;top: 15px" @click="updateClassDes"
                    >确认</Button
                  >
                  <Button type="primary" style="margin-left: 12px;border-radius:15px;position: relative;top: 15px" @click="showUpdateDes = false"
                    >取消</Button
                  >
                </div>
                <div v-if="course.isOwner && !showUpdateDes" @click="classDesUpdate(course.description)" style="cursor: pointer">
                  <svg class="icon" style="margin-left: 12px;color: #A0A6ADFF" aria-hidden="true">
                    <use xlink:href="#icon-caozuobianji" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="detail-item">
              专业:<Tag v-for="(item, index) in course.postNameVoS" :key="index">{{ item.name }}</Tag>
            </div>
            <div class="detail-item" v-if="abilityList.length !== 0">
              能力：<Tag :name="item.abilityId" v-for="(item, index) in abilityList" :key="index" closable @on-close="closeAbility">{{
                item.abilityName
              }}</Tag>
            </div>
            <Button v-if="course.isOwner" icon="ios-add" type="dashed" size="small" @click="bindTarget">绑定目标</Button>
            <div style="display: flex;flex-wrap: wrap;width: 80%">
              <div class="addListName" v-for="addList in targetLists" :key="addList.id">
                <span style="white-space: nowrap">{{ addList.name }}</span>
                <div
                  style="display: flex;align-items: center;width: 12px;justify-content: center;margin-left: 5px"
                  @mouseenter="showDele(addList)"
                  @click="deltarget(addList)"
                  @mouseleave="showDel = false"
                >
                  <i class="el-icon-close"></i>
                  <!--                <svg-->
                  <!--                  -->
                  <!--                  class="icon"-->
                  <!--                  aria-hidden="true"-->
                  <!--                  style="height: 5px;width: 5px;color: #4579EA;"-->
                  <!--                >-->
                  <!--                  <use xlink:href="#icon-guanbi1" />-->
                  <!--                </svg>-->
                  <!--                <svg-->
                  <!--                  v-if="showDel === true && knowledgeId === addList.id"-->
                  <!--                  class="icon"-->
                  <!--                  aria-hidden="true"-->
                  <!--                  style="height: 12px;width: 12px;color: #4579EA;"-->
                  <!--                >-->
                  <!--                  <use xlink:href="#icon-cha-copy" />-->
                  <!--                </svg>-->
                </div>
              </div>
              <!--            <Button icon="ios-add" type="dashed" size="small" @click="addModal = true">添加能力</Button>-->
              <!--            <Button v-if="abilityList.length !== 0" icon="ios-trash-outline" type="warning" ghost size="small" @click="delModal = true" style="margin-left: 8px">删除能力</Button>-->
            </div>
            <div class="detail-item" style="margin-top: 12px">
              <div>
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-shoucang" />
                </svg>
                {{ course.favoriteTimes }}
              </div>
              <Divider type="vertical" />
              <div style="display: flex;align-items: center">
                <Icon type="ios-person-outline" style="font-size: 20px;" />
                {{ course.students ? course.students.length : 0 }}
              </div>
              <!--            <div style="padding-left: 20px">-->
              <!--              <svg class="icon" aria-hidden="true">-->
              <!--                <use xlink:href="#icon-dianzan"></use>-->
              <!--              </svg>-->
              <!--              167-->
              <!--            </div>-->
            </div>
          </div>

          <!--直播部分-->
          <!--        <div class="live-container">-->
          <!--          <div>-->
          <!--            <div class="live-header">最近直播：</div>-->
          <!--            <div v-for="live in liveList" :key="live.sectionId">-->
          <!--              <span style="margin-right: 10px">{{live.name}}</span>-->
          <!--              <span style="margin-right: 10px">{{live.startTime | formatTime('yyyy-MM-dd hh:mm')}}</span>-->
          <!--              <span>{{live.estimateTime}} 小时</span>-->
          <!--            </div>-->
          <!--            <div v-if="!hasLive">暂无直播</div>-->
          <!--          </div>-->

          <!--          <Button v-if="hasLive" class="live-btn btn-shadow" type="primary" @click="handleBeginLive">-->
          <!--            <svg class="icon" aria-hidden="true">-->
          <!--              <use xlink:href="#icon-kaishizhibo"></use>-->
          <!--            </svg>-->
          <!--            开始直播-->
          <!--          </Button>-->
          <!--        </div>-->
        </div>

        <!--操作按钮-->
        <!--      <div class="opt-btn-container">-->
        <!--        <Button class="filter-btn btn-shadow" size="large" type="primary">-->
        <!--          <svg class="icon" aria-hidden="true">-->
        <!--            <use xlink:href="#icon-shangchuan"></use>-->
        <!--          </svg>-->
        <!--          上传视频-->
        <!--        </Button>-->
        <!--        <Button class="filter-btn btn-shadow" size="large" type="primary">-->
        <!--          <svg class="icon" aria-hidden="true">-->
        <!--            <use xlink:href="#icon-shangchuan"></use>-->
        <!--          </svg>-->
        <!--          上传资料-->
        <!--        </Button>-->
        <!--        <Button class="filter-btn btn-shadow" size="large" type="primary" :to="`/manage/course/practice/newPaper/${courseId}`">-->
        <!--          <svg class="icon" aria-hidden="true">-->
        <!--            <use xlink:href="#icon-fabu"></use>-->
        <!--          </svg>-->
        <!--          发布练习-->
        <!--        </Button>-->
        <!--        <Button class="filter-btn btn-shadow" size="large" type="primary" :to="`/manage/course/exam/newPaper/${courseId}`">-->
        <!--          <svg class="icon" aria-hidden="true">-->
        <!--            <use xlink:href="#icon-fabu"></use>-->
        <!--          </svg>-->
        <!--          发布考试-->
        <!--        </Button>-->
        <!--      </div>-->

        <Tabs v-model="currentTab" class="tabs">
          <TabPane label="目录" name="catalog" />
          <!--        <TabPane label="视频" name="video"></TabPane>-->
          <!--        <TabPane label="资料" name="data"></TabPane>-->
          <TabPane label="考试" name="practice" />
          <TabPane label="问卷" name="questionnaire" />
          <TabPane label="评价" name="evaluation" />
          <!--        <TabPane label="学员" name="member"></TabPane>-->
          <!--        <TabPane label="考试" name="exam"></TabPane>-->
        </Tabs>

        <transition name="fade" mode="out-in">
          <CourseCatalog
            ref="courseCatalog"
            v-if="currentTab === 'catalog'"
            @input="input"
            :isOwner="course.isOwner"
            :courseId="courseId"
            :folder-id="this.course.folderId || 0"
            v-model="course.chapters"
          />
          <CourseVideo v-if="currentTab === 'video'" :courseId="courseId" />
          <CourseData v-if="currentTab === 'data'" :courseId="courseId" />
          <!--        <CoursePractice v-if="currentTab === 'practice'" :courseId="courseId"/>-->
          <courseNewExam v-if="currentTab === 'practice'" :isOwner="course.isOwner" :courseId="courseId" />
          <CourseMember v-if="currentTab === 'member'" :courseId="courseId" />
          <CourseExam v-if="currentTab === 'exam'" :courseId="courseId" />
          <CourseQuestionnaire v-if="currentTab === 'questionnaire'" :isOwner="course.isOwner" :courseId="courseId" />
          <courseEva v-if="currentTab === 'evaluation'" :isOwner="course.isOwner" :courseId="courseId" />
        </transition>
      </div>
    </div>
    <el-dialog
      :visible="addModal"
      title="添加能力"
      @close="
        addModal = false
        loading = false
        selectAbilityId = []
      "
      class="yt-dialog yt-dialog-default"
    >
      <el-cascader
        v-model="selectAbilityId"
        :options="allAbility"
        :props="{
          checkStrictly: true,
          children: 'abilities',
          label: 'name',
          value: 'id',
          expandTrigger: 'hover',
          multiple: true
        }"
        :show-all-levels="false"
        filterable
        placeholder="请选择能力"
        style="width: 100%"
        ref="cascader"
        clearable
      >
      </el-cascader>
      <div class="footer">
        <Button
          class="button"
          @click="
            addModal = false
            loading = false
            selectAbilityId = []
          "
          >取消</Button
        >
        <Button class="button" :disabled="selectAbilityId.length === 0" type="primary" :loading="loading" @click="addCourseAbility">确定</Button>
      </div>
    </el-dialog>
    <el-dialog
      class="yt-dialog yt-dialog-default"
      :visible="delModal"
      title="删除能力"
      @close="
        delModal = false
        loading = false
        selectAbilityId = []
      "
    >
      <div class="ability">
        <CheckboxGroup v-model="selectAbilityId">
          <Checkbox
            class="list"
            :style="{
              background: selectAbilityId.indexOf(item.abilityId) > -1 ? '#EBF0F6' : 'white'
            }"
            :label="item.abilityId"
            v-for="(item, index) in abilityList"
            :key="index"
          >
            <span style="font-size: 14px;margin-left: 4px">{{ item.abilityName }}</span>
          </Checkbox>
        </CheckboxGroup>
      </div>
      <div class="footer">
        <Button
          class="button"
          @click="
            delModal = false
            loading = false
            selectAbilityId = []
          "
          >取消</Button
        >
        <Button class="button" :disabled="selectAbilityId.length === 0" type="primary" :loading="loading" @click="delCourseAbility">确定</Button>
      </div>
    </el-dialog>
    <el-dialog :visible="showAddTarget" title="绑定目标" class="yt-dialog yt-dialog-medium" @close="cancelAdd">
      <div class="addTarget">
        <div style="width: 50%;height: 100%;overflow: auto;border-right: 1px solid #E4E9EF">
          <CheckboxGroup v-model="targetVal">
            <Checkbox v-for="target in targetList" :key="target.id" :label="target.id" class="target-checkbox">
              {{ target.name }}
            </Checkbox>
          </CheckboxGroup>
        </div>
        <div style="width: 50%;height: 100%;overflow: auto">
          <div class="top">
            <span>已绑定目标</span>
            <Button type="primary" @click="clearKnowledge = true">清空</Button>
          </div>
          <div
            v-for="addList in targetLists"
            :key="addList.id"
            style="display: flex;align-items: center"
            @mouseenter="targetsEnter(addList)"
            @mouseleave="targetsEnterId = 0"
          >
            <div style="position: relative;left:12px;width: 8px;cursor: pointer">
              <svg v-if="targetsEnterId === addList.id" @click="deltarget(addList)" class="icon" aria-hidden="true" font-size="8">
                <use xlink:href="#icon-guanbi1" />
              </svg>
            </div>
            <div class="addList">{{ addList.name }}</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <el-button @click="cancelAdd">取消</el-button>
        <el-button type="primary" @click="addCourseTargets">确认</el-button>
      </div>
    </el-dialog>
    <DeleteModal v-model="delSingle" :title="delName" :loading="deleteLoading" @on-ok="clearSingleStarget"> </DeleteModal>
    <DeleteModal v-model="clearKnowledge" :title="`确认清空已绑定目标吗？`" :loading="deleteLoading" @on-ok="clearStarget"> </DeleteModal>
  </div>
</template>
<script>
import courseApi from '@api/course'
import CourseCatalog from './catalog/CourseCatalog'
import courseNewExam from './member/courseNewExam'
import CourseVideo from './CourseVideo'
import CourseData from './CourseData'
import CoursePractice from './CoursePractice'
import CourseMember from './member/CourseMember'
import CourseExam from './CourseExam'
import courseEva from './evaluation'
import DeleteModal from '@components/common/DeleteModal'
import CourseQuestionnaire from '../detail/CourseQuestionnaire'
import functionApi from '@api/functionApi'
import ossApi from '@api/oss'
import config from '../../../../../config/config'

export default {
  components: {
    CourseQuestionnaire,
    CourseCatalog,
    CourseVideo,
    CourseData,
    CoursePractice,
    CourseMember,
    CourseExam,
    courseEva,
    DeleteModal,
    courseNewExam,
    'remote-js': {
      render(createElement) {
        return createElement('script', {
          attrs: { type: 'text/javascript', src: this.src }
        })
      },
      props: {
        src: { type: String, required: true }
      }
    }
  },
  created() {
    this.loading = true
    let params = this.$handleParams('courseDetailData')
    this.courseId = params.courseId
    this.getAddTarget()
    this.$Loading.start()
    let promise = Promise.all([
      courseApi.getCourseDetail(params.courseId).then(res => {
        let data = res.res
        data.chapters = data.chapters
          .map(chapter => {
            chapter.sections.sort((a, b) => a.sequence - b.sequence)
            return chapter
          })
          .sort((a, b) => a.sequence - b.sequence)
        this.course = data
      }),
      courseApi.getLatestLiveList(this.courseId).then(res => {
        this.liveList = res.res
      })
    ])

    promise.finally(() => {
      this.loading = false
      this.$Loading.finish()
    })
    functionApi.getAllAbilityData().then(res => {
      this.allAbility = res.res
    })
    // this.getCourse()
    this.getCourseAbility()
  },
  data() {
    return {
      clearKnowledge: false,
      deleteLoading: false,
      delName: '',
      serveLoca: '',
      delSingle: false,
      targetLists: [],
      targetVal: [],
      showAddTarget: false,
      showIcon: false,
      uploadUrl: '',
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      hoverAvatar: false,
      showUpdateDes: false,
      showUpdateName: false,
      currentTab: 'catalog',
      course: {},
      courseId: '',
      liveList: [],
      courseList: [],
      abilityList: [],
      selectAbilityId: [],
      allAbility: [],
      addModal: false,
      delModal: false,
      loading: false,
      targetList: [],
      targetsEnterId: 0,
      delTargetId: 0,
      targetListId: 0,
      knowledgeId: 0,
      showDel: false,
      updateName: '',
      desUpdate: '',
      fromUrl: '' //记录跳转来的路径
    }
  },
  methods: {
    updateClass() {
      let payload = {
        courseId: this.course.courseId,
        cover: this.course.cover,
        description: this.course.description,
        name: this.course.name
      }
      courseApi.editCourse(payload).then(res => {
        this.$message.success('编辑成功')
      })
    },
    handleUploadCoverSuccess(response, file) {
      if (window.uploadUrl) {
        this.course.cover = this.serveLoca
      } else {
        this.course.cover = `${this.uploadUrl}/${this.uploadParams.key}`
      }
      this.updateClass()
    },
    beforeUpload(file) {
      if (window.uploadUrl) {
        // 局域网
        let forData = new FormData()
        forData.append('file', file)
        ossApi.upCover(forData).then(res => {
          this.serveLoca = res.res
          this.handleUploadCoverSuccess()
        })
      } else {
        ossApi.upType().then(() => {
          ossApi.getQuestionBankCoverToken(new Date().valueOf(), file.name).then(res => {
            const data = res.res
            this.uploadUrl = `https://${data.host}`
            this.uploadParams.key = data.dir
            this.uploadParams.OSSAccessKeyId = data.accessId
            this.uploadParams.policy = data.policy
            this.uploadParams.Signature = data.signature
            let formData = new FormData()
            formData.append('key', this.uploadParams.key)
            formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
            formData.append('policy', this.uploadParams.policy)
            formData.append('Signature', this.uploadParams.Signature)
            formData.append('file', file)
            fetch(`https://${data.host}`, {
              method: 'POST',
              body: formData
            }).then(data => {
              this.handleUploadCoverSuccess()
            })
          })
        })
      }
      return false
    },
    classDesUpdate(data) {
      this.desUpdate = data
      this.showUpdateDes = !this.showUpdateDes
    },
    nameUpdate(data) {
      this.showUpdateName = !this.showUpdateName
      this.updateName = data
    },
    showDele(data) {
      this.showDel = true
      this.knowledgeId = data.id
    },
    clearStarget() {
      courseApi.clearCourseTarget(this.courseId).then(res => {
        this.$message.success('目标已清空')
        this.getAddTarget()
        this.clearKnowledge = false
        this.$refs.courseCatalog.getCourseDetail()
        this.$refs.courseCatalog.getTarget()
      })
    },
    targetEnter(data) {
      this.targetListId = data.id
    },
    bindTarget() {
      this.showAddTarget = true
      this.getTarget()
      this.getAddTarget()
    },
    clearSingleStarget() {
      courseApi.delCourseTarget(this.courseId, this.delTargetId).then(res => {
        this.$message.success('目标已删除')
        this.delSingle = false
        this.getAddTarget()
        this.delName = ''
        this.$refs.courseCatalog.getCourseDetail()
        this.$refs.courseCatalog.getTarget()
      })
    },
    deltarget(data) {
      this.delTargetId = data.id
      this.delSingle = true
      this.delName = '确定删除' + data.name + '吗？'
    },
    targetsEnter(data) {
      this.targetsEnterId = data.id
    },
    getTarget() {
      courseApi.getTarget().then(res => {
        this.targetList = res.res
      })
    },
    cancelAdd() {
      this.targetVal = []
      this.showAddTarget = false
    },
    getAddTarget() {
      courseApi.getCourseTarget(this.courseId).then(res => {
        this.targetLists = res.res
      })
    },
    addCourseTargets() {
      if (this.targetVal.length === 0) {
        this.showAddTarget = false
      } else {
        let payload = {
          courseId: this.courseId,
          targetIds: this.targetVal
        }
        courseApi.addCourseTarget(payload).then(res => {
          this.$message.success('目标添加成功')
          this.getAddTarget()
          this.$refs.courseCatalog.getCourseDetail()
          this.$refs.courseCatalog.getTarget()
          this.targetVal = []
        })
      }
    },
    // updateCancle(data) {
    //   this.course.name = data
    //   this.showUpdateName = false
    // },
    toCourse() {
      if (this.fromUrl === 'postCourseList') {
        this.$router.push({ path: '/manage/post/course' })
      } else {
        this.$router.push({ path: '/manage/study/course' })
      }
    },
    updateClassName() {
      if (this.course.name !== this.updateName) {
        this.updateClass()
      }
      this.showUpdateName = false
    },
    updateClassDes() {
      if (this.course.description !== this.desUpdate) {
        this.updateClass()
      }
      this.showUpdateDes = false
    },
    input() {
      this.$Loading.start()

      let promise = Promise.all([
        courseApi.getCourseDetail(this.$route.params.id).then(res => {
          let data = res.res
          data.chapters = data.chapters
            .map(chapter => {
              chapter.sections.sort((a, b) => a.sequence - b.sequence)
              return chapter
            })
            .sort((a, b) => a.sequence - b.sequence)
          this.course = data
        }),
        courseApi.getLatestLiveList(this.courseId).then(res => {
          this.liveList = res.res
        })
      ])
    },
    toFunctional() {
      if (this.$route.query.showName) {
        this.$router.push({
          path: '/course/functional',
          query: {
            addCourse: this.$route.query.addCourse,
            groupId: this.$route.query.groupId,
            postId: this.$route.query.postId,
            showName: this.$route.query.showName
          }
        })
      } else {
        this.$router.push({
          path: '/course/post',
          query: {
            addCourse: this.$route.query.addCourse,
            postId: this.$route.query.postId
          }
        })
      }
    },
    closeAbility(val, name) {
      this.selectAbilityId = [name]
      this.delCourseAbility()
    },
    getCourseAbility() {
      courseApi.getCourseAbility(this.courseId).then(res => {
        this.abilityList = res.res
      })
    },
    addCourseAbility() {
      this.loading = true
      let arr = this.abilityList.map(item => {
        return item.abilityId
      })
      let ids = this.$refs.cascader.getCheckedNodes().map(item => {
        return item.value
      })
      for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < ids.length; j++) {
          if (ids[j] == arr[i]) {
            ids.splice(j, 1)
            j = j - 1
          }
        }
      }
      if (ids.length === 0) {
        this.$message.warning('已有该能力, 请重新选择')
        this.loading = false
        return
      }
      courseApi
        .addCourseAbility(this.courseId, ids)
        .then(res => {
          if (res.res) {
            this.$message.success('添加成功')
            this.addModal = false
            this.selectAbilityId = []
            this.getCourseAbility()
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    delCourseAbility() {
      this.loading = true
      courseApi
        .delCourseAbility(this.courseId, this.selectAbilityId)
        .then(res => {
          if (res.res) {
            this.$message.success('删除成功')
            this.delModal = false
            this.selectAbilityId = []
            this.getCourseAbility()
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // getCourse() {
    //   courseApi.getCoursePracticeList(this.courseId).then(res => {
    //     this.courseList = res.res.data
    //     this.$refs.courseCatalog.getList(this.courseList)
    //   })
    // },
    updateCoursePublicity(publicity) {
      this.$Spin.show()

      if (publicity === 0) {
        courseApi.openCourse(this.courseId).then(res => {
          this.$Spin.hide()
          this.reload()
        })
      } else {
        courseApi.closeCourse(this.courseId).then(res => {
          this.$Spin.hide()
          this.reload()
        })
      }
      // courseApi
      //   .updateCoursePublicity({
      //     courseId: this.courseId,
      //     publicity: publicity
      //   })
      //   .then(() => {
      //     this.course.publicity = publicity
      //   })
      //   .finally(() => {
      //     this.$Spin.hide()
      //   })
    },
    handleBeginLive() {
      const liveSection = this.liveList[0]
      this.$router.push(`/live/${liveSection.sectionId}/${this.course.roomId}`)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fromUrl = from.name
    })
  },
  computed: {
    hasLive() {
      return this.liveList && this.liveList.length
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../theme/variables';

.course-container {
  height: 100%;
  .course-content-wrapper {
    background-color: @layout-body-background;
    padding: 10px;
    height: 100%;
  }
  .content {
    text-align: left;
    background-color: white;
    height: 100%;
    overflow-y: auto;
    box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);

    ::v-deep .ivu-tag {
      cursor: default;
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      background-color: #e4e9ef;
      font-size: 16px;
      color: @font-color-content;

      .course-operation {
        font-size: 12px;

        span {
          margin-left: 6px;
          cursor: pointer;
          color: @primary-color;
          transition: color @default-transition;

          &:hover {
            color: @primary-hover-color;
          }

          &:active,
          &:focus {
            color: @primary-active-color;
          }
        }
      }
    }
    .detailCover {
      background-color: rgba(0, 0, 0, 0.5);
      height: 200px;
      width: 314px;
      position: absolute;
      left: 0px;
      top: 0px;
    }
    .detail {
      display: flex;
      padding: 20px 24px;

      img {
        width: 314px;
        height: 200px;
      }

      .right-detail {
        width: calc(100% - 334px);
        flex: 13 1 auto;
        margin-left: 20px;
        color: @font-color-content;
        font-size: 14px;
        padding-right: 40px;

        .detail-item {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 10px;

          .item-label {
            flex: 0 0 auto;
            width: 500px;
            font-size: 18px;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
            opacity: 1;
            span {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .live-container {
        flex: 5 0 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 2px solid #6a8abe;
        padding: 0 20px;
        margin: 40px 0;
        font-size: 14px;
        color: @font-color-content;

        .live-header {
          margin-bottom: 10px;
          font-weight: bold;
        }

        .live-btn {
          width: 120px;
          margin-top: 10px;
        }
      }
    }

    .opt-btn-container {
      padding: 0 24px;
      margin-bottom: 10px;

      .filter-btn {
        min-width: 100px;
        margin-right: 20px;
      }
    }

    ::v-deep .tabs {
      .ivu-tabs-bar {
        padding: 0 20px;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }
}
.ability {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(228, 233, 239, 1);
  opacity: 1;
  border-radius: 4px;
  overflow: auto;
  margin-top: 16px;
  padding: 0 8px;
  max-height: calc(100vh - 325px);
  .list {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    margin: 4px;
    &:hover {
      background: #ebf0f6 !important;
    }
  }
}
.delModal ::v-deep .ivu-modal-body {
  padding: 0 50px 20px 50px;
}
::v-deep .ivu-upload-drag {
  background: none;
  border: none;
  &:hover {
    border: none;
  }
}
.toCourse {
  cursor: pointer;
  &:hover {
    color: #2d8cf0;
  }
}
.addTarget {
  height: 393px;
  border: 1px solid #e4e9ef;
  display: flex;
}
.top {
  color: #747475;
  font-size: 14px;
  padding: 20px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.addList {
  height: 30px;
  color: #555555;
  font-size: 13px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.addListName {
  cursor: pointer;
  background: #ecf2fc;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 12px 5px;
  color: #4579ea;
  font-size: 14px;
  border-radius: 3px;
}
.target-checkbox {
  width: 100%;
  height: 44px;
  padding-left: 20px;
  line-height: 44px;
  margin-right: 0;
  text-align: left;
  &:hover {
    background-color: #ecf2fc;
  }
  ::v-deep .ivu-checkbox {
    margin-right: 8px;
  }
}
</style>
