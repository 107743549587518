<template>
  <div class="table-container">
    <Table :columns="columns" :data="tableData" class="flex-table" :loading="loading">
      <template slot-scope="{ row, index }" slot="name">
        <div class="nowrap" :title="row.name">{{ row.name }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="section">
        <div class="nowrap" :title="`${row.chapter} / ${row.section}`">
          {{ `${row.chapter} / ${row.section}` }}
        </div>
      </template>
      <template slot-scope="{ row, index }" slot="count">
        <span>{{ row.times }}</span>
      </template>
      <!--      <template slot-scope="{row, index}" slot="create-time">-->
      <!--        <span>{{row.createTime | formatTime('yyyy-MM-dd hh:mm')}}</span>-->
      <!--      </template>-->
      <!--      <template slot-scope="{row, index}" slot="end-time">-->
      <!--        <span>{{row.endTime | formatTime('yyyy-MM-dd hh:mm')}}</span>-->
      <!--      </template>-->
      <template slot-scope="{ row, index }" slot="status">
        <div class="status-container">
          <div class="status" :style="{ backgroundColor: getStatus(row.status).color }"></div>
          {{ getStatus(row.status).label }}
        </div>
      </template>
      <template slot-scope="{ row, index }" slot="opt">
        <div class="operation">
          <Tooltip content="详情" placement="top">
            <svg class="icon" aria-hidden="true" @click="handleViewPractice(row)">
              <use xlink:href="#icon-xiangqing"></use>
            </svg>
          </Tooltip>
          <Dropdown style="margin-left: 20px" @on-click="handleClickMenu($event, row)">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-gengduo-2"></use>
            </svg>
            <DropdownMenu slot="list">
              <DropdownItem name="edit" v-if="row.status !== PRACTICE_STATUS.PUBLISH.value">编辑</DropdownItem>
              <DropdownItem name="release" v-if="row.status !== PRACTICE_STATUS.PUBLISH.value">发布</DropdownItem>
              <DropdownItem name="close" v-else>关闭</DropdownItem>
              <DropdownItem name="delete">删除</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </template>
    </Table>

    <Page size="small" :current.sync="currentPage" :total="total" style="margin-top: 10px" />

    <DeleteModal v-model="deleteVisible" :title="`确认删除练习 '${selectedPractice.name}'`" :loading="deleteLoading" @on-ok="deletePractice">
      <span>删除练习后不可恢复，你还要继续吗？</span>
    </DeleteModal>
  </div>
</template>
<script>
import courseApi from '@api/course'
import examApi from '@api/exam'
import { getStatusByValue, PRACTICE_STATUS } from '@/util/practiceStatus'
import DeleteModal from '@/components/common/DeleteModal'

export default {
  components: { DeleteModal },
  props: {
    courseId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      deleteVisible: false,
      deleteLoading: false,
      selectedPractice: {},
      tableData: [],
      currentPage: 1,
      pageSize: 5,
      total: 0,
      columns: [
        {
          title: '考试名称',
          slot: 'name',
          key: 'name',
          className: 'name-column',
          sortable: true,
          tooltip: true
        },
        {
          title: '所属小节',
          slot: 'section',
          className: 'section-column'
        },
        {
          title: '考试次数',
          slot: 'count',
          key: 'times',
          className: 'count-column',
          sortable: true
        },
        {
          title: '状态',
          slot: 'status',
          className: 'status-column',
          filters: Object.values(PRACTICE_STATUS),
          filterMethod: (value, row) => {
            return value === row.status
          }
        },
        {
          title: '操作',
          slot: 'opt',
          className: 'opt-column'
        }
      ]
    }
  },
  created() {
    this.requestData()
  },
  methods: {
    async requestData() {
      this.loading = true
      try {
        const { res } = await courseApi.getCoursePracticeList(this.courseId)
        this.tableData = res.data
        this.total = res.total
      } finally {
        this.loading = false
      }
    },
    getStatus(status) {
      return getStatusByValue(status)
    },
    handleViewPractice(row) {
      window.open(`/manage/course/practice/detail/${this.courseId}/${row.practiceId}`)
    },
    handleClickMenu(name, row) {
      switch (name) {
        case 'edit':
          this.handleEditPractice(row)
          break
        case 'release':
          this.handlePublishPractice(row)
          break
        case 'close':
          this.handleClosePractice(row)
          break
        case 'delete':
          this.handleDeletePractice(row)
          break
        default:
          return
      }
    },
    handleEditPractice(row) {
      window.open(`/manage/course/practice/edit/${this.courseId}/${row.practiceId}`)
    },
    handleClosePractice(row) {
      examApi.closePractice(row.practiceId).then(() => {
        row.status = PRACTICE_STATUS.CLOSE.value
      })
    },
    handleDeletePractice(row) {
      this.selectedPractice = row
      this.deleteVisible = true
    },
    handlePublishPractice(row) {
      examApi.closePractice(row.practiceId).then(() => {
        row.status = PRACTICE_STATUS.PUBLISH.value
      })
    },
    deletePractice() {
      if (!this.selectedPractice.practiceId) {
        return
      }

      this.deleteLoading = true
      courseApi
        .deletePractice(this.selectedPractice.practiceId)
        .then(() => {
          this.tableData.splice(
            this.tableData.findIndex(practice => practice.practiceId === this.selectedPractice.practiceId),
            1
          )
          this.$message.success('删除成功')
        })
        .finally(() => {
          this.deleteLoading = false
          this.deleteVisible = false
        })
    }
  },
  computed: {
    PRACTICE_STATUS() {
      return PRACTICE_STATUS
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../theme/variables';

.table-container {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .flex-table {
    border-bottom: 1px solid #e8eaec;

    .name-column {
      flex: auto;
    }

    .section-column {
      width: 20%;
    }

    .count-column {
      width: 10%;
    }

    .create-time-column {
      width: 15%;
    }

    .end-time-column {
      width: 15%;
    }

    .status-column {
      width: 10%;

      .status-container {
        display: flex;
        align-items: center;

        .status {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }
    }

    .opt-column {
      width: 10%;

      .operation {
        cursor: pointer;
        margin-right: 10px;
        color: @primary-color;
        transition: all @default-transition;

        &:hover {
          color: @primary-hover-color;
        }
      }
    }
  }
}
</style>
