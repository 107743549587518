var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('Table',{staticClass:"flex-table",attrs:{"columns":_vm.columns,"data":_vm.tableData,"loading":_vm.loading},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(row.name))])]}},{key:"size",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm._f("filesize")(row.size)))])]}},{key:"type",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm._f("fileType")(row.type)))])]}},{key:"time",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.uploadTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('a',{attrs:{"href":row.datumUrl,"download":""}},[_vm._v("下载")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }