<template>
  <div class="table-container">
    <Table :columns="columns" :data="tableData" :loading="loading">
      <template slot-scope="{ row, index }" slot="name">
        <div class="nowrap" :title="row.name">{{ row.name }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="paper">
        <div class="nowrap" :title="row.paper">{{ row.paper }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="start-time">
        <span>{{ $formatTime(row.startTime, 'yyyy-MM-dd hh:mm') }}</span>
      </template>
      <template slot-scope="{ row, index }" slot="end-time">
        <span>{{ $formatTime(row.endTime, 'yyyy-MM-dd hh:mm') }}</span>
      </template>
      <template slot-scope="{ row, index }" slot="total">
        <div class="nowrap">{{ row.total }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="limit-time">
        <div class="nowrap">{{ row.limitTime }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="condition">
        <div class="nowrap">
          {{ row.condition && row.condition > 0 ? `不低于总分 ${Math.round(row.condition * 100)}%` : '无' }}
        </div>
      </template>
      <template slot-scope="{ row, index }" slot="repeated">
        <div class="nowrap">{{ row.repeated }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="examinee-count">
        <div class="nowrap">{{ row.examineeCount }}</div>
      </template>
      <!--      <template slot-scope="{row, index}" slot="confirm-count">-->
      <!--        <div class="nowrap">{{row.confirmCount}}</div>-->
      <!--      </template>-->
      <template slot-scope="{ row, index }" slot="submit-count">
        <div class="nowrap">{{ row.submitCount }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="pass-count">
        <div class="nowrap">{{ row.passCount }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="status">
        <div class="nowrap">{{ getStatus(row.status).label }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="opt">
        <div class="operation">
          <Dropdown transfer @on-click="handleClickMenu($event, row)">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-gengduo-2" />
            </svg>
            <DropdownMenu slot="list">
              <DropdownItem name="remark">分配批阅</DropdownItem>
              <DropdownItem name="preview">查看详情</DropdownItem>
              <DropdownItem name="delete">删除</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </template>
    </Table>

    <Page size="small" :current.sync="currentPage" :total="total" style="margin-top: 10px" />

    <DeleteModal v-model="deleteVisible" :title="`确认删除考试 '${selectedExam.name}'`" :loading="deleteLoading" @on-ok="deleteExam">
      <span>删除考试后不可恢复，你还要继续吗？</span>
    </DeleteModal>
    <ExamRemarkAllocateModal v-model="showAllocateModal" ref="remark-allocate-modal" />
  </div>
</template>
<script>
import courseApi from '@api/course'
import { getStatusByValue, EXAM_STATUS } from '@/util/examStatus'
import DeleteModal from '@/components/common/DeleteModal'
import ExamRemarkAllocateModal from '@/components/manage/exam/exam/ExamRemarkAllocateModal'

export default {
  components: { DeleteModal, ExamRemarkAllocateModal },
  props: {
    courseId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      showAllocateModal: false,
      deleteVisible: false,
      deleteLoading: false,
      selectedExam: {},
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 5,
      columns: [
        {
          title: '考试名称',
          slot: 'name',
          className: 'name-column',
          sortable: true,
          tooltip: true,
          fixed: 'left',
          width: 200
        },
        {
          title: '试卷名称',
          slot: 'paper',
          className: 'paper-column',
          width: 200
        },
        {
          title: '开始时间',
          key: 'startTime',
          slot: 'start-time',
          className: 'start-time-column',
          sortable: true,
          sortType: 'desc',
          width: 140
        },
        {
          title: '结束时间',
          slot: 'end-time',
          key: 'endTime',
          className: 'end-time-column',
          sortable: true,
          width: 140
        },
        {
          title: '总分',
          slot: 'total',
          key: 'total',
          className: 'total-column',
          sortable: true,
          width: 100
        },
        {
          title: '时长/分钟',
          slot: 'limit-time',
          key: 'limitTime',
          sortable: true,
          className: 'limit-time-column',
          width: 120
        },
        {
          title: '通过条件',
          slot: 'condition',
          className: 'condition-column',
          width: 160
        },
        {
          title: '可重考次数',
          slot: 'repeated',
          className: 'repeated-column',
          width: 140
        },
        {
          title: '考生人数',
          slot: 'examinee-count',
          className: 'examinee-count-column',
          width: 120
        },
        // {
        //   title: '确认人数',
        //   slot: 'confirm-count',
        //   className: 'confirm-count-column',
        //   width: 120
        // },
        {
          title: '提交人数',
          slot: 'submit-count',
          className: 'submit-count-column',
          width: 120
        },
        {
          title: '通过人数',
          slot: 'pass-count',
          className: 'pass-count-column',
          width: 120
        },
        {
          title: '状态',
          slot: 'status',
          className: 'status-column',
          filters: Object.values(EXAM_STATUS),
          filterMethod: (value, row) => {
            return value === row.status
          },
          width: 140
        },
        {
          title: '操作',
          slot: 'opt',
          className: 'opt-column',
          fixed: 'right',
          width: 100
        }
      ]
    }
  },
  created() {
    this.requestData()
  },
  methods: {
    async requestData() {
      this.loading = true
      try {
        const { res } = await courseApi.getCourseExams(this.courseId, this.currentPage, this.pageSize)
        this.tableData = res.data
        this.total = res.total
      } finally {
        this.loading = false
      }
    },
    handlePageChange(newPage) {
      this.currentPage = newPage
    },
    getStatus(status) {
      return getStatusByValue(status)
    },
    handleClickMenu(name, row) {
      switch (name) {
        case 'delete':
          this.handleDeleteExam(row)
          break
        case 'preview':
          this.handlePreviewExam(row)
          break
        case 'remark':
          this.handleRemarkAllocate(row)
          break
        default:
          break
      }
    },
    handleDeleteExam(row) {
      this.selectedExam = row
      this.deleteVisible = true
    },
    handlePreviewExam(row) {
      this.$router.push(`/manage/exam/detail/${row.examId}`)
    },
    handleRemarkAllocate(row) {
      this.showAllocateModal = true
      this.$refs['remark-allocate-modal'].setCurrentExamId(row.examId)
    },
    deleteExam() {
      if (!this.selectedExam.examId) {
        return
      }

      this.deleteLoading = true
      courseApi
        .deleteCouresExam(this.selectedExam.examId)
        .then(() => {
          this.tableData.splice(
            this.tableData.findIndex(exam => exam.examId === this.selectedExam.examId),
            1
          )
          this.$message.success('删除成功')
        })
        .finally(() => {
          this.deleteLoading = false
          this.deleteVisible = false
        })
    }
  },
  computed: {},
  watch: {
    currentPage(newVal) {
      this.requestData()
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../theme/variables';

.table-container {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .ivu-table {
    .opt-column {
      .operation {
        cursor: pointer;
        color: @primary-color;
        transition: all @default-transition;

        &:hover {
          color: @primary-hover-color;
        }
      }
    }
  }
}
</style>
