<template>
  <Modal v-model="visible" title="添加学生" width="620" class="member-modal" @on-cancel="close" :mask-closable="false">
    <div class="tree-container">
      <div>
        <div class="header">
          <div class="title">员工</div>
          <div class="number">已选择: {{ leftSelectedNodes.length }}</div>
        </div>
        <Tree ref="leftTree" class="tree" :data="staffTree || []" show-checkbox multiple check-directly></Tree>
      </div>
      <div class="opt-container">
        <Button size="small" @click="handleRemoveMembers" :disabled="!rightSelectedNodes.length"><Icon type="ios-arrow-back"/></Button>
        <Button size="small" @click="handleSelectMembers" :disabled="!leftSelectedNodes.length"><Icon type="ios-arrow-forward"/></Button>
      </div>
      <div>
        <div class="header">
          <div class="title">学员</div>
          <div class="number">已选择: {{ rightSelectedNodes.length }}</div>
        </div>
        <Tree ref="rightTree" class="tree" :data="selectedTree || []" empty-text="未选择" show-checkbox multiple check-directly></Tree>
      </div>
    </div>

    <div slot="footer">
      <Button @click="close" class="modal-btn">取消</Button>
      <Button type="primary" class="modal-btn" @click="addCourseMembers" :disabled="selectedIds.length === 0" :loading="loading">确定 </Button>
    </div>
  </Modal>
</template>
<script>
import courseApi from '@api/course'
import departmentMixin from '@/components/mixins/departmentMixin'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    courseId: {
      type: Number,
      required: true
    },
    courseMemberIds: {
      type: Array,
      required: true
    }
  },
  mixins: [departmentMixin],
  data: function() {
    return {
      visible: this.value,
      loading: false,
      selectedIds: []
    }
  },
  created() {
    const courseId = this.$route.params['id']
    this.$store.dispatch('department/getStaffs', courseId)
  },
  methods: {
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
      this.selectedIds = []
    },
    handleSelectMembers() {
      this.selectedIds = this.leftSelectedNodes.map(node => node.detail.userId)
    },
    handleRemoveMembers() {
      const rightIds = this.rightSelectedNodes.map(node => node.detail.userId)
      this.selectedIds = this.selectedIds.filter(id => rightIds.indexOf(id) === -1)
    },
    addCourseMembers() {
      this.loading = true

      courseApi
        .addCourseMembers({
          courseId: this.courseId,
          students: this.selectedIds
        })
        .then(() => {
          this.$emit('on-success')
          this.close()
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    ...mapGetters({
      staffTreeExclude: 'department/staffTreeExclude',
      staffTreeInclude: 'department/staffTreeInclude'
    }),
    //左侧树需要过滤已选择的员工和课程中本就存在的学生
    staffTree() {
      return this.staffTreeExclude(this.courseMemberIds.concat(this.selectedIds))
    },
    selectedTree() {
      return this.staffTreeInclude(this.selectedIds)
    },
    leftSelectedNodes() {
      //staffTree 是为了触发 computed 的重新计算
      return this.staffTree && this.$refs.leftTree
        ? this.$refs.leftTree.getCheckedNodes().filter(node => {
            return node.isStaff && !node.disabled
          })
        : []
    },
    rightSelectedNodes() {
      //selectedTree 是为了触发 computed 的重新计算
      return this.selectedTree && this.$refs.rightTree
        ? this.$refs.rightTree.getCheckedNodes().filter(node => {
            return node.isStaff && !node.disabled
          })
        : []
    }
  },
  watch: {
    value(val) {
      this.visible = val
    },
    departmentTree(newVal, oldVal) {
      const courseId = this.$route.params['id']
      if (newVal && !oldVal) {
        this.$store.dispatch('department/getStaffs', courseId)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../../theme/variables';

.member-modal ::v-deep .ivu-modal {
  .ivu-select-placeholder {
    font-size: 14px;
  }

  .tree-container {
    display: flex;
    justify-content: space-between;

    .opt-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        margin: 20px 0;
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #e4e9ef;
      color: @font-color-base;
      border: 1px solid #e4e9ef;
      border-radius: 4px;
      margin-bottom: 10px;
      padding: 4px 6px;
    }

    .tree {
      height: 320px;
      width: 230px;
      border: 1px solid #e4e9ef;
      border-radius: 4px;
      overflow: scroll;
    }
  }

  .ivu-modal-footer {
    .modal-btn:first-child {
      margin-right: 20px;
    }

    .modal-btn {
      .ivu-icon {
        font-size: 14px;
      }
    }
  }
}
</style>
