<template>
  <div class="table-container">
    <Table :columns="columns" :data="tableData" class="flex-table" :loading="loading">
      <template slot-scope="{ row, index }" slot="name">
        <span>{{ row.name }}</span>
      </template>
      <template slot-scope="{ row, index }" slot="size">
        <span>{{ row.size | filesize }}</span>
      </template>
      <template slot-scope="{ row, index }" slot="type">
        <span>{{ row.type | fileType }}</span>
      </template>
      <template slot-scope="{ row, index }" slot="time">
        <span>{{ $formatTime(row.uploadTime, 'yyyy-MM-dd hh:mm') }}</span>
      </template>
      <template slot-scope="{ row, index }" slot="action">
        <a :href="row.datumUrl" download>下载</a>
      </template>
    </Table>
  </div>
</template>
<script>
import courseApi from '@api/course'
import filesize from 'filesize'
import { getFileTypeName } from '@/util/fileType'

export default {
  props: ['courseId'],
  data() {
    return {
      loading: false,
      tableData: [],
      columns: [
        {
          title: '资料名称',
          slot: 'name',
          key: 'name',
          className: 'name-column',
          sortable: true
        },
        {
          title: '大小',
          slot: 'size',
          key: 'size',
          className: 'size-column',
          sortable: true
        },
        {
          title: '类型',
          slot: 'type',
          className: 'type-column'
        },
        {
          title: '上传时间',
          slot: 'time',
          key: 'uploadTime',
          className: 'time-column',
          sortable: true,
          sortType: 'desc'
        },
        {
          title: '操作',
          slot: 'action',
          className: 'operation-column'
        }
      ]
    }
  },
  created() {
    this.loading = true
    courseApi.getCourseDatumsList(this.courseId).then(res => {
      this.loading = false
      this.tableData = res.res
    })
  },
  methods: {},
  filters: {
    filesize(data) {
      return filesize(data, { round: 2 })
    },
    fileType(data) {
      return getFileTypeName(data)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../../theme/variables';

.table-container {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .flex-table {
    border-bottom: 1px solid #e8eaec;

    .name-column {
      width: 40%;
    }

    .size-column {
      width: 15%;
    }

    .type-column {
      width: 10%;
    }

    .time-column {
      width: 20%;
    }

    .operation-column {
      width: 15%;
    }
  }
}
</style>
