import { render, staticRenderFns } from "./AddLiveSectionModal.vue?vue&type=template&id=6471524b&scoped=true&"
import script from "./AddLiveSectionModal.vue?vue&type=script&lang=js&"
export * from "./AddLiveSectionModal.vue?vue&type=script&lang=js&"
import style0 from "./AddLiveSectionModal.vue?vue&type=style&index=0&id=6471524b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6471524b",
  null
  
)

export default component.exports