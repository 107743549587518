<template>
  <Modal v-model="visiable" :mask-closable="false" :footer-hide="true" width="548" class="modal" :on-cancle="close">
    <div class="title">编辑视频小节</div>
    <div class="name">
      <p style="margin-right: 15px">视频名称:</p>
      <Input v-model="editorVideoByLive.title" />
    </div>
    <div class="url">
      <p style="margin-right: 15px">视频链接:</p>
      <Upload :before-upload="handleBeforeUpload" type="drag" :format="['jpg', 'jpeg', 'png']" style="width: 100%">
        <div style="display: flex;justify-content:center;height: 65px;align-items: center">
          <JYIcon href="#icon-shangchuan" style="color: #3399ff; font-size: 42px;margin-right: 12px" />
          <p>点击或拖放上传视频图片</p>
        </div>
      </Upload>
      <Input disabled v-model="editorVideoByLive.videoUrl" />
    </div>
    <div style="display: flex;justify-content: flex-start">
      <p style="margin-right: 40px">封面:</p>
      <Upload
        :before-upload="beforeUpload"
        type="drag"
        style="width: 100%"
        :data="uploadParams"
        :action="actionUrl"
        v-model="editorVideoByLive.cover"
        :format="['jpg', 'jpeg', 'png']"
        :on-success="uploadCoverSuccess"
      >
        <div style="display: flex;justify-content:center;height: 65px;align-items: center">
          <JYIcon href="#icon-shangchuan" style="color: #3399ff; font-size: 42px;margin-right: 12px" />
          <p>点击或拖放上传视频图片</p>
        </div>
      </Upload>
    </div>
    <div class="desc">
      <p style="margin-right: 15px">视频简介:</p>
      <Input v-model="editorVideoByLive.desc" />
    </div>
    <div class="footer">
      <Button @click="close">取消</Button>
      <Button type="primary" @click="ok">确定</Button>
    </div>
  </Modal>
</template>

<script>
import JYIcon from './JYIcon'
import ossApi from '../../api/oss'
import courseApi from '../../api/course'
import tcVod from 'vod-js-sdk-v6'
import VideoAPI from '@api/video'
export default {
  name: 'edtiorVideoByLiveModal',
  async created() {
    this.tcVod = new TcVod({
      getSignature: VideoAPI.getVodUploadSignature
    })
  },
  components: { JYIcon },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    editorVideoByLive: {
      type: Object,
      default: null
    }
  },
  // mounted() {
  //   console.log(123)
  //   console.log(this.editorVideoByLive)
  // },
  data() {
    return {
      visiable: this.value,
      actionUrl: '',
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      imageObject: {
        name: '',
        url: ''
      }
    }
  },
  methods: {
    close() {
      this.visiable = false
      this.$emit('close')
    },
    ok() {
      let payload = {
        courseId: this.editorVideoByLive.courseId,
        chapterId: this.editorVideoByLive.chapterId,
        title: this.editorVideoByLive.title,
        teacherId: 0,
        description: this.editorVideoByLive.desc,
        sequence: this.editorVideoByLive.sequence,
        videoUrl: this.editorVideoByLive.videoUrl,
        size: 0,
        cover: this.imageObject.url
      }
      courseApi.addVideoSection(payload).then(res => {
        this.visiable = false
        this.$emit('addNewVideo')
      })
    },
    beforeUpload(file) {
      const payload = {
        courseId: this.editorVideoByLive.courseId,
        name: file.name
      }
      return ossApi.getCourseCoverToken(payload).then(res => {
        const data = res.res
        this.actionUrl = `https://${data.host}`
        this.uploadParams.key = data.dir
        this.uploadParams.OSSAccessKeyId = data.accessId
        this.uploadParams.policy = data.policy
        this.uploadParams.Signature = data.signature
      })
    },
    uploadCoverSuccess(res, file) {
      this.imageObject.name = file.name
      this.imageObject.url = `${this.actionUrl}/${this.uploadParams.key}`
    }
  }
}
</script>

<style lang="less" scoped>
.modal {
  font-size: 14px;
  font-weight: 500;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-bottom: 10px;
  }
  .title {
    font-size: 18px;
    height: 45px;
    font-weight: 600;
  }
  .footer {
    text-align: center;
    margin-top: 20px;
    button {
      width: 86px;
      height: 30px;
    }
    button:first-child {
      margin-right: 15px;
    }
  }
}
::v-deep .ivu-upload .ivu-upload-drag {
  width: 100%;
}
</style>
