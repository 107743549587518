var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.tableData,"loading":_vm.loading},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap",attrs:{"title":row.name}},[_vm._v(_vm._s(row.name))])]}},{key:"paper",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap",attrs:{"title":row.paper}},[_vm._v(_vm._s(row.paper))])]}},{key:"start-time",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.startTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"end-time",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.endTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"total",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap"},[_vm._v(_vm._s(row.total))])]}},{key:"limit-time",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap"},[_vm._v(_vm._s(row.limitTime))])]}},{key:"condition",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(row.condition && row.condition > 0 ? ("不低于总分 " + (Math.round(row.condition * 100)) + "%") : '无')+" ")])]}},{key:"repeated",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap"},[_vm._v(_vm._s(row.repeated))])]}},{key:"examinee-count",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap"},[_vm._v(_vm._s(row.examineeCount))])]}},{key:"submit-count",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap"},[_vm._v(_vm._s(row.submitCount))])]}},{key:"pass-count",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap"},[_vm._v(_vm._s(row.passCount))])]}},{key:"status",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"nowrap"},[_vm._v(_vm._s(_vm.getStatus(row.status).label))])]}},{key:"opt",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"operation"},[_c('Dropdown',{attrs:{"transfer":""},on:{"on-click":function($event){return _vm.handleClickMenu($event, row)}}},[_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-gengduo-2"}})]),_c('DropdownMenu',{attrs:{"slot":"list"},slot:"list"},[_c('DropdownItem',{attrs:{"name":"remark"}},[_vm._v("分配批阅")]),_c('DropdownItem',{attrs:{"name":"preview"}},[_vm._v("查看详情")]),_c('DropdownItem',{attrs:{"name":"delete"}},[_vm._v("删除")])],1)],1)],1)]}}])}),_c('Page',{staticStyle:{"margin-top":"10px"},attrs:{"size":"small","current":_vm.currentPage,"total":_vm.total},on:{"update:current":function($event){_vm.currentPage=$event}}}),_c('DeleteModal',{attrs:{"title":("确认删除考试 '" + (_vm.selectedExam.name) + "'"),"loading":_vm.deleteLoading},on:{"on-ok":_vm.deleteExam},model:{value:(_vm.deleteVisible),callback:function ($$v) {_vm.deleteVisible=$$v},expression:"deleteVisible"}},[_c('span',[_vm._v("删除考试后不可恢复，你还要继续吗？")])]),_c('ExamRemarkAllocateModal',{ref:"remark-allocate-modal",model:{value:(_vm.showAllocateModal),callback:function ($$v) {_vm.showAllocateModal=$$v},expression:"showAllocateModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }