<template>
  <div class="table-container" v-loading="loading" element-loading-text="加载中...">
    <Table v-if="!table.length" :columns="Column" :data="table" class="itable">
      <template slot-scope="{ row, index }" slot="name">
        <div class="nowrap" :title="row.name">{{ row.name }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="condition">
        <div class="nowrap">
          {{ row.condition && row.condition > 0 ? `总分不低于${row.condition}分` : '无' }}
        </div>
      </template>
      <template slot-scope="{ row, index }" slot="createdTime">
        <span>{{ $formatTime(row.createdTime, 'yyyy-MM-dd hh:mm') }}</span>
      </template>
      <template slot-scope="{ row, index }" slot="op">
        <Icon
          @click.stop="
            examId = row.examId
            deleteVisible = true
          "
          type="ios-trash-outline"
          style="font-size: 24px;cursor: pointer"
        />
      </template>
    </Table>
    <Table v-else :columns="Column" :data="table">
      <template slot-scope="{ row, index }" slot="name">
        <div class="nowrap" :title="row.name">{{ row.name }}</div>
      </template>
      <template slot-scope="{ row, index }" slot="condition">
        <div class="nowrap">
          {{ row.condition && row.condition > 0 ? `总分不低于${row.condition}分` : '无' }}
        </div>
      </template>
      <template slot-scope="{ row, index }" slot="createdTime">
        <span>{{ $formatTime(row.createdTime, 'yyyy-MM-dd hh:mm') }}</span>
      </template>
      <template v-if="isOwner" slot-scope="{ row, index }" slot="op">
        <Icon
          @click.stop="
            examId = row.examId
            deleteVisible = true
          "
          type="ios-trash-outline"
          style="font-size: 24px;cursor: pointer"
        />
      </template>
    </Table>
    <div v-if="isOwner && table.length === 0" style="margin: 16px 16px;color: #6A8ABE;font-size: 14px;cursor: pointer" @click.stop="addModal = true">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-xinzeng" />
      </svg>
      新增考试
    </div>
    <DeleteModal v-model="deleteVisible" :title="'确认删除考试'" :loading="deleteLoading" @on-ok="delExam">
      <span>删除考试后不可恢复，你还要继续吗？</span>
    </DeleteModal>
    <Modal v-model="addModal" @on-cancel="cancel" :width="1280">
      <div slot="header" style="font-size: 20px;color: #3A4E64;text-align: center">
        选择考试
      </div>
      <div>
        <div style="text-align: center;color: #5E6470;font-size: 12px">
          从以下选择考试
        </div>
        <div class="selectTable" style="display: flex;justify-content: center" stripe>
          <!--          <Input class="searchInput" v-model="searchObj.examName" search placeholder="请输入考试名称"  @on-search="advSearch"/>-->
          <Table highlight-row @on-current-change="selectPaper" height="550" :columns="columns" :data="tableData" style="overflow: auto">
            <template slot-scope="{ row }" slot="radio">
              <Radio :value="row.examId === newExam" />
            </template>
            <template slot-scope="{ row, index }" slot="name">
              <div class="nowrap" :title="row.name">{{ row.name }}</div>
            </template>
            <template slot-scope="{ row, index }" slot="paper">
              <div class="nowrap" :title="row.paper" style="cursor: pointer;color: blue">
                {{ row.paper }}
              </div>
            </template>
            <template slot-scope="{ row, index }" slot="start-time">
              <span>{{ $formatTime(row.startTime, 'yyyy-MM-dd hh:mm') }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="end-time">
              <span>{{ $formatTime(row.endTime, 'yyyy-MM-dd hh:mm') }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="total">
              <div class="nowrap">{{ row.total }}</div>
            </template>
            <template slot-scope="{ row, index }" slot="limit-time">
              <div class="nowrap">{{ row.limitTime }}</div>
            </template>
            <template slot-scope="{ row, index }" slot="condition">
              <div class="nowrap">
                {{ row.condition && row.condition > 0 ? `不低于总分 ${Math.round(row.condition * 100)}%` : '无' }}
              </div>
            </template>
            <template slot-scope="{ row, index }" slot="status">
              <div class="nowrap">{{ getStatus(row.status).label }}</div>
            </template>
          </Table>
        </div>
      </div>
      <div></div>
      <div slot="footer" style="display: flex;justify-content: center;">
        <el-pagination
          layout="total, prev, pager, next, sizes"
          @size-change="handleSizeChange"
          @current-change="handePageChange"
          :page-size="size"
          :page-sizes="[10, 20, 40, 100]"
          :total="total"
        />
        <div style="margin-left: 30px">
          <el-button size="small" @click="cancel">取消</el-button>
          <el-button size="small" type="primary" @click="addExam">确定</el-button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import DeleteModal from '@components/common/DeleteModal'
import courseApi from '@api/course'
import examApi from '@api/exam'

export default {
  name: 'courseNewExam',
  components: { DeleteModal },
  props: {
    isOwner: {
      type: Number
    },
    courseId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      searchObj: {
        examName: '',
        examPaperName: '',
        status: ''
      },
      paperData: [],
      addModal: false,
      deleteLoading: false,
      deleteVisible: false,
      loading: false,
      tableData: [],
      newExam: '',
      table: [],
      examId: 0,
      page: 0,
      size: 10,
      total: 0,
      Column: [
        {
          title: '考试名称',
          key: 'name',
          slot: 'name'
        },
        {
          title: '试卷库名称',
          key: 'paperBlankName'
        },
        {
          title: '创建人',
          key: 'creatorName'
        },
        {
          title: '创建时间',
          key: 'createdTime',
          slot: 'createdTime'
        },
        {
          title: '总分',
          key: 'total'
        },
        {
          title: '时长/分钟',
          key: 'limitTime'
        },
        {
          title: '通过条件',
          key: 'condition',
          slot: 'condition'
        },
        {
          title: '操作',
          key: 'op',
          slot: 'op'
        }
      ],
      columns: [
        {
          title: '#',
          slot: 'radio',
          width: 60,
          align: 'center'
        },
        {
          title: '考试名称',
          slot: 'name',
          className: 'paper-column',
          width: 200
        },
        {
          title: '总分',
          slot: 'total',
          key: 'total',
          className: 'total-column',
          sortable: true,
          width: 90
        },
        {
          title: '时长/分钟',
          slot: 'limit-time',
          key: 'limitTime',
          sortable: true,
          className: 'limit-time-column',
          width: 150
        },
        {
          title: '通过条件',
          slot: 'condition',
          className: 'condition-column',
          width: 200
        }
        // {
        //   title: '操作',
        //   slot: 'opt',
        //   className: 'opt-column',
        //   fixed: 'right',
        //   width: 100
        // }
      ]
    }
  },
  created() {
    this.getNewExam()
    this.initTableData(this.page, this.size)
  },
  methods: {
    selectPaper(data) {
      this.newExam = data.examId
    },
    addExam() {
      if (this.newExam === '') {
        this.$message.error('请选择考试')
        return
      } else {
        courseApi.addNewExam(this.courseId, this.newExam).then(res => {
          this.$message.success('添加成功')
          this.addModal = false
          this.getNewExam()
        })
      }
    },
    initTableData(page, size) {
      this.loading = true
      examApi
        .getExamList(page, size)
        .then(res => {
          this.tableData = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSizeChange(size) {
      this.page = 0
      this.size = size
      this.initTableData(this.page, this.size)
    },
    handePageChange(page) {
      this.page = page - 1
      this.initTableData(this.page, this.size)
    },
    cancel() {
      this.addModal = false
      this.paperData = []
      this.paperId = ''
      this.keyword = ''
      this.selectPaperId = ''
      this.confirmLoading = false
    },
    getNewExam() {
      courseApi.getCourseNewExams(this.courseId).then(res => {
        this.table = res.res
      })
    },
    delExam() {
      courseApi.deleteCouresNewExam(this.courseId, this.examId).then(res => {
        this.deleteVisible = false
        this.getNewExam()
        this.$message.success('删除成功')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../../theme/variables';

.table-container {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .flex-table {
    border-bottom: 1px solid #e8eaec;

    .name-column {
      flex: auto;
    }

    .section-column {
      width: 20%;
    }

    .count-column {
      width: 10%;
    }

    .create-time-column {
      width: 15%;
    }

    .end-time-column {
      width: 15%;
    }

    .status-column {
      width: 10%;

      .status-container {
        display: flex;
        align-items: center;

        .status {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }
    }

    .opt-column {
      width: 10%;

      .operation {
        cursor: pointer;
        margin-right: 10px;
        color: @primary-color;
        transition: all @default-transition;

        &:hover {
          color: @primary-hover-color;
        }
      }
    }
  }
}
::v-deep .itable td {
  display: none;
}
</style>
