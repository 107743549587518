export const FILE_TYPE = {
  FOLDER: 0,
  EXCEL: 1,
  PDF: 2,
  WORD: 3,
  PPT: 4,
  TEXT: 5,
  VIDEO: 6,
  IMG: 7,
  UNKNOWN: 8
}

const EXTENSION_TABLE = [
  ['xls', 'xlsx'],
  ['pdf'],
  ['doc', 'docx'],
  ['ppt', 'pptx'],
  ['txt'],
  ['avi', 'mp4', 'rmvb', 'mov', 'mpg', 'mpeg', 'wmv', 'mkv', 'vob'],
  ['png', 'jpg', 'jpeg', 'gif', 'bmp']
]
const FILE_TYPE_TABLE = [
  { type: FILE_TYPE.EXCEL, name: '表格' },
  { type: FILE_TYPE.PDF, name: 'PDF' },
  { type: FILE_TYPE.WORD, name: '文档' },
  { type: FILE_TYPE.PPT, name: 'PPT' },
  { type: FILE_TYPE.TEXT, name: '文本' },
  { type: FILE_TYPE.VIDEO, name: '视频' },
  { type: FILE_TYPE.IMG, name: '图片' }
]

export const getFileType = fileName => {
  const extension = /[.]/.exec(fileName) ? /[^.]+$/.exec(fileName).pop() : null

  if (!extension) {
    return FILE_TYPE.UNKNOWN
  }

  for (let i = 0; i < EXTENSION_TABLE.length; i++) {
    if (EXTENSION_TABLE[i].includes(extension)) {
      return FILE_TYPE_TABLE[i].type
    }
  }

  return FILE_TYPE.UNKNOWN
}

export const getFileTypeName = fileType => {
  const result = FILE_TYPE_TABLE.find(item => item.type === fileType)
  return result ? result.name : '未知'
}
