<template>
  <el-dialog
    :visible="visible"
    :title="isAdd ? '新增视频小节' : '修改视频小节'"
    class="yt-dialog yt-dialog-default section-modal"
    @close="close"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <!--视频表单-->
    <Form ref="videoForm" :model="videoForm" :rules="videoRuleValidate" :label-width="80">
      <FormItem label="视频名称" prop="name">
        <Input v-model="videoForm.name" placeholder="请输入视频名称" />
      </FormItem>
      <!--      <FormItem label="视频讲师" prop="videoTeacherId" :required="false">-->
      <!--        <AddTeacher v-model="videoForm.teacherId"></AddTeacher>-->
      <!--      </FormItem>-->
      <FormItem label="视频" prop="fakeUrl">
        <Input v-model="videoForm.fakeUrl" style="display: none" />
        <!--        <video id="video-style" src="#"></video>-->
        <Upload accept=".mp4,.avi,.wmv,.mpeg" :before-upload="handleBeforeUpload" action="" type="drag" class="uploader-fixed-size">
          <div style="display: flex;justify-content:center;height: 65px;align-items: center">
            <JYIcon href="#icon-shangchuan" style="color: #3399ff; font-size: 42px;margin-right: 12px" />
            <p v-if="!isUploading">点击或拖放上传视频mp4、avi 等</p>
          </div>
        </Upload>
        <div v-if="videoObject.file" style="" class="cover-item">
          <div class="left">
            <JYIcon href="#icon-shipin2" style="width: 36px; height: 36px" />
          </div>
          <div class="middle">
            <span style="font-weight: 900">{{ videoObject.name }}</span>
          </div>
          <div class="right">
            <Icon style="color: red;cursor: pointer" @click="onCancle" type="md-close-circle" size="16" />
          </div>
          <div class="progress" :style="`width: ${uploadProgress}%`" :class="{ finish: isUploadFinished }">
            <span class="progress-text">{{ uploadProgress }}%</span>
          </div>
        </div>
        <div style="display: flex;align-items: center">
          <span style="color: #A0A6ADFF;white-space: nowrap;position: absolute;left: -32px">或者</span>
          <Input v-model="inputVideo.url" placeholder="请输入视频地址" @on-change="videoForm.fakeUrl = inputVideo.url" />
          <!--          <div style="display: flex;align-items: center;width: 70px">-->
          <!--            <InputNumber style="margin: 4px;flex: auto" :min="0" v-model="inputVideo.size" placeholder="请输入视频大小"/>-->
          <!--            <div>MB</div>-->
          <!--          </div>-->
        </div>
      </FormItem>
      <FormItem label="封面" prop="fakeImg">
        <Input v-model="videoForm.fakeImg" style="display: none" />
        <Upload
          :headers="coverType === 0 ? {} : token"
          :before-upload="beforeUpload"
          type="drag"
          :data="uploadParams"
          :action="actionUrl"
          v-if="!imageObject.url"
          accept=".png,.jpg,.jpeg,.gif"
          v-model="imageObject.url"
          :format="['jpg', 'jpeg', 'png']"
          :on-success="uploadCoverSuccess"
        >
          <div style="display: flex;justify-content:center;height: 65px;align-items: center">
            <JYIcon href="#icon-shangchuan" style="color: #3399ff; font-size: 42px;margin-right: 12px" />
            <p>点击或拖放上传图片jpg、png 等</p>
          </div>
        </Upload>
        <div class="imgUrl" v-if="imageObject.url !== ''">
          <img :src="imageObject.url" alt="" style="height: 100%;width: 45px" />
          <div style="width: 100%;text-align: center;line-height: 37px">
            {{ imageObject.name.length > 10 ? imageObject.name.substr(0, 10) + '...' : imageObject.name }}
          </div>
          <div style="margin-right: 12px;line-height: 37px;cursor: pointer" @click="delImg">
            <svg class="icon" aria-hidden="true" font-size="8">
              <use xlink:href="#icon-guanbi1" />
            </svg>
          </div>
        </div>
        <div style="display: flex">
          <span style="color: #A0A6ADFF;white-space: nowrap;position: absolute;left: -32px">或者</span>
          <Input v-model="inputVideo.cover" placeholder="请输入封面地址" @on-change="videoForm.fakeImg = inputVideo.cover" />
        </div>
        <div class="form-tip">
          上传视频和封面或输入视频和封面地址，支持 mp4 格式
        </div>
      </FormItem>
      <FormItem label="视频简介" prop="description">
        <Input type="textarea" v-model="videoForm.description" placeholder="请输入视频简介" />
      </FormItem>
    </Form>

    <div class="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="handleUpload" :loading="isUploading">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import courseApi from '@api/course'
import DateTimePicker from '@components/common/DateTimePicker'
import AddTeacher from '@components/common/AddTeacher'
import sectionTypes from '@/util/sectionType'
import VideoUploader from '@components/common/VideoUploader'
import TcVod from 'vod-js-sdk-v6'
import VideoAPI from '@api/video'
import JYIcon from '@components/common/JYIcon'
import JYProgress from '@components/common/JYProgress'
import ossApi from '@api/oss'
import config from '../../../../../../config/config'

export default {
  components: {
    ossApi,
    DateTimePicker,
    AddTeacher,
    VideoUploader,
    JYIcon,
    JYProgress
  },
  props: {
    coverType: {
      type: Number,
      default: 1
    },
    value: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    //修改小节时小节本身
    section: {
      type: Object
    },
    courseId: {
      type: Number,
      required: true
    },
    //添加小节时需要的章节 id
    chapterId: {
      type: Number,
      default: -1
    },
    //添加小节时小节位置，已有小节数量加 1
    sequence: {
      type: Number
    }
  },
  async created() {
    this.tcVod = new TcVod({
      getSignature: VideoAPI.getVodUploadSignature
    })
  },
  computed: {
    readyToUpload() {
      if (this.imageObject.file === undefined) {
        return this.videoObject.file
      } else {
        return this.videoObject.file && this.imageObject.file
      }
    }
  },
  data() {
    return {
      token: {
        Authorization: 'Bearer ' + (localStorage.getItem('auth') && JSON.parse(localStorage.getItem('auth')).access_token)
      },
      classUpdata: {
        classUrl: ''
      },
      actionUrl: config.baseUrl + '/file/api/v1/upload/cover',
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      },
      isUploading: false,
      isUploadFinished: false,
      uploadProgress: 0,
      duration: 0, // 视频时长
      uploadCover: 0,
      uploadedList: [],
      // imageUrl: '',
      serveLoca: '',
      imageObject: {
        name: '',
        url: '',
        base64: '',
        file: null
      },
      videoObject: {
        name: '',
        targetId: null,
        url: '',
        size: 0,
        file: null
      },
      supportVideoTypeMap: ['video/mp4', 'video/x-flv', 'video/flv'],
      supportImageTypeMap: ['image/jpeg', 'image/jpg', 'image/png'],
      visible: this.value,
      loading: false,
      videoForm: {
        name: this.isAdd ? '' : this.section.name,
        teacherId: this.isAdd ? '' : this.section.teacherId,
        description: this.isAdd ? '' : this.section.description,
        url: '',
        fakeUrl: '',
        fakeImg: ''
      },
      videoRuleValidate: {
        name: [
          {
            required: true,
            message: '视频名称不能为空'
          },
          {
            type: 'string',
            max: 80,
            message: '视频名称不超过 80 个字'
          }
        ],
        description: {
          type: 'string',
          required: true,
          message: '5~600个字',
          min: 5,
          max: 600
        },
        teacherId: {
          type: 'number',
          required: false
        },
        fakeUrl: {
          required: true,
          message: '请上传视频',
          trigger: 'blur'
        },
        fakeImg: {
          required: true,
          message: '请上传封面',
          trigger: 'blur'
        }
      },
      videoUploadResult: {
        video: {},
        cover: {}
      },
      inputVideo: {
        url: this.isAdd ? '' : this.section.videoUrl,
        size: this.isAdd ? '' : this.section.size,
        cover: this.isAdd ? '' : this.section.cover
      }
    }
  },
  inject: ['reload'],
  methods: {
    delImg() {
      this.imageObject.url = ''
      this.imageObject.name = ''
    },
    uploadCoverSuccess(res, file) {
      // this.imageObject.name = file.name
      if (window.uploadUrl) {
        this.imageObject.url = this.serveLoca
        this.videoForm.fakeImg = this.serveLoca
      } else {
        this.imageObject.url = `${this.actionUrl}/${this.uploadParams.key}`
        this.videoForm.fakeImg = `${this.actionUrl}/${this.uploadParams.key}`
      }
      // if (this.coverType === 0) {
      //   this.imageObject.url = `${this.actionUrl}/${this.uploadParams.key}`
      // } else {
      //   this.imageObject.url = res.res
      // }
    },
    beforeUpload(file) {
      if (window.uploadUrl) {
        let forData = new FormData()
        forData.append('file', file)
        ossApi.upCover(forData).then(res => {
          this.serveLoca = res.res
          this.uploadCoverSuccess()
        })
      } else {
        // if (this.coverType === 0) {
        const payload = {
          courseId: new Date().valueOf(),
          name: file.name
        }
        return ossApi.getCourseCoverToken(payload).then(res => {
          const data = res.res
          this.actionUrl = `https://${data.host}`
          this.uploadParams.key = data.dir
          this.uploadParams.OSSAccessKeyId = data.accessId
          this.uploadParams.policy = data.policy
          this.uploadParams.Signature = data.signature
          let formData = new FormData()
          formData.append('key', this.uploadParams.key)
          formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
          formData.append('policy', this.uploadParams.policy)
          formData.append('Signature', this.uploadParams.Signature)
          formData.append('file', file)
          fetch(`https://${data.host}`, {
            method: 'POST',
            body: formData
          }).then(data => {
            this.uploadCoverSuccess()
          })
        })
      }
      // }
      return false
    },
    onCancle() {
      this.videoObject = {
        name: '',
        targetId: null,
        url: '',
        size: 0,
        file: null
      }
    },
    unAble() {
      this.inputVideo.url = ''
      this.inputVideo.cover = ''
    },
    handleBeforeUpload(file) {
      this.reset()
      // if (window.uploadUrl) {
      //   // 局域网上传视频
      //   ossApi.upVideo(this.courseId, 0, file).then(res => {
      //     // console.log('res')
      //     // console.log(res)
      //   })
      // } else {
      if (this._isVideoType(file.type)) {
        this.videoObject = {
          name: file.name,
          size: file.size,
          file: file
        }
        this.videoForm.fakeUrl = file.name
      }
      if (this._isImageType(file.type)) {
        const reader = new FileReader()
        reader.onload = ({ target }) => {
          const base64 = target.result
          this.imageObject.base64 = base64
        }
        reader.readAsDataURL(file)
        this.imageObject.file = file
        this.imageObject.name = file.name
      }
      // }
      if (file.type.indexOf('video') !== -1 && !this._isVideoType(file.type)) {
        this.$message.error('支持的视频文件格式：' + this.supportVideoTypeMap.join(', '))
      }
      if (file.type.indexOf('image') !== -1 && !this._isImageType(file.type)) {
        this.$message.error('支持的封面格式：' + this.supportImageTypeMap.join(', '))
      }
      return false
    },
    _isVideoType(mimeString) {
      return this.supportVideoTypeMap.indexOf(mimeString) !== -1
    },
    _isImageType(mimeString) {
      return this.supportImageTypeMap.indexOf(mimeString) !== -1
    },
    countTime() {
      setTimeout(() => {
        if (this.isAdd === true) {
          this.addVideoSection()
        } else {
          this.videoUpdate()
        }
      }, 2000)
    },
    handleUpload() {
      this.$refs.videoForm.validate(valid => {
        if (valid) {
          if (this.videoObject.file !== null) {
            if (!window.uploadUrl) {
              const payload = {
                videoFile: this.videoObject.file,
                coverFile: this.imageObject.file
              }
              this.isUploading = true
              const uploader = this.tcVod.upload(payload)
              uploader.on('video_progress', info => {
                let progress = Math.floor(info.percent * 100)
                if (progress === 1 && !this.isUploadFinished) {
                  progress = 0.99
                }
                this.uploadProgress = progress
              })
              uploader.on('cover_progress', info => {
                let progress = Math.floor(info.percent * 100)
                if (progress === 1 && !this.isUploadFinished) {
                  progress = 0.99
                }
                this.uploadCover = progress
              })
              return uploader.done().then(data => {
                this.videoObject.url = data.video.url
                this.videoObject.targetId = data.fileId
                this.countVideoTime(data.video.url)
              })
            } else {
              this.isUploading = true
              let formData = new FormData()
              formData.append('file', this.videoObject.file)
              let part = 0
              const config = {
                onUploadProgress: progressEvent => {
                  this.uploadProgress = Number(((progressEvent.loaded / progressEvent.total) * 100).toFixed(2))
                }
              }
              ossApi.upVideo(this.courseId, part, formData, config).then(res => {
                this.videoObject.url = res.res
                this.countVideoTime(res.res)
              })
            }
          } else {
            this.duration = 0
            if (this.isAdd === true) {
              this.addVideoSection()
            } else {
              this.videoUpdate()
            }
          }
        }
      })
    },
    countVideoTime(url) {
      // 计算视频的总时长
      let audioE = new Audio(url)
      this.lunXunGetTime(audioE)
    },
    lunXunGetTime(audioE) {
      let v = setTimeout(() => {
        if (audioE.readyState > 0) {
          this.duration = audioE.duration
          clearTimeout(v)
          this.countTime()
        } else {
          this.lunXunGetTime(audioE)
        }
      }, 1000)
    },
    videoUpdate() {
      if (this.imageObject.url === '' && this.inputVideo.cover === '') {
        this.$message.warning('请上传图片')
      } else {
        // 封面地址
        if (this.imageObject.url !== '') {
          this.inputVideo.cover = this.imageObject.url
        } else {
          // this.inputVideo.cover = this.section.cover
        }
        // 视频地址
        if (this.videoObject.url !== '') {
          this.inputVideo.url = this.videoObject.url
        } else {
          // this.inputVideo.url = this.section.videoUrl
        }
        let payload = {
          // inputVideo.size
          targetId: this.section.targetId,
          sectionId: this.section.sectionId,
          title: this.videoForm.name,
          teacherId: this.videoForm.teacherId,
          description: this.videoForm.description,
          sequence: this.sequence,
          duration: this.section.duration || 0,
          videoUrl: this.inputVideo.url,
          size: this.inputVideo.size * 1024 * 1024,
          cover: this.inputVideo.cover
        }
        if (this.inputVideo.size === 0) {
          delete payload.size
        }
        courseApi
          .updateVideoSection(payload)
          .then(() => {
            this.close()
            this.$emit('on-add-success')
          })
          .finally(() => {
            this.isUploadFinished = true
            this.isUploading = false
            this.$parent.getData()
          })
      }
    },
    reset() {
      this.isUploadFinished = false
      this.isUploading = false
      this.uploadProgress = 0
    },
    close() {
      this.visible = false
      this.$refs.videoForm.resetFields()
      this.inputVideo.url = ''
      this.inputVideo.size = ''
      this.inputVideo.cover = ''
      this.imageObject.url = ''
      this.$emit('input', false)
      this.$emit('on-cancel')
      this.reload()
    },
    addVideoSection() {
      let payload
      if (this.imageObject.url !== '') {
        this.inputVideo.cover = this.imageObject.url
      }
      if (this.videoObject.url !== '') {
        this.inputVideo.url = this.videoObject.url
      }
      if (this.inputVideo.size === 0) {
        this.inputVideo.size = this.videoObject.size
      } else {
        this.inputVideo.size = this.inputVideo.size * 1024 * 1024
      }
      payload = {
        courseId: this.courseId,
        chapterId: this.chapterId,
        title: this.videoForm.name,
        teacherId: this.videoForm.teacherId,
        description: this.videoForm.description,
        sequence: this.sequence,
        videoUrl: this.inputVideo.url,
        size: this.inputVideo.size,
        duration: this.duration,
        cover: this.inputVideo.cover
      }
      courseApi
        .addVideoSection(payload)
        .then(() => {
          this.close()
          this.$emit('on-add-success')
        })
        .finally(() => {
          this.isUploading = false
          this.isUploadFinished = true
          this.$parent.getData()
        })
    },
    updateVideoForm(section) {
      if (this.isAdd || String(section.type) !== sectionTypes.VIDEO.value) {
        return
      }
      this.videoForm = {
        name: section.name,
        teacherId: section.teacherId,
        description: section.description,
        fakeUrl: section.videoUrl,
        fakeImg: section.cover
      }
    },
    handleUploadFinish(data) {
      this.videoUploadResult = data
    }
  },
  watch: {
    value(val) {
      this.visible = val
      if (val && this.isAdd) {
        this.$refs.videoForm.resetFields()
      }
    },
    isAdd(val) {
      // 编辑状态和新增状态互相转换时需要重置表单
      if (val) {
        this.$refs.videoForm.resetFields()
      } else {
        this.updateVideoForm(this.section)
      }
      this.inputVideo = {
        url: this.isAdd ? '' : this.section.videoUrl,
        size: 0,
        cover: this.isAdd ? '' : this.section.cover
      }
    },
    section(val) {
      this.updateVideoForm(val)
      this.inputVideo = {
        url: this.isAdd ? '' : val.videoUrl,
        size: 0,
        cover: this.isAdd ? '' : val.cover
      }
    }
  }
}
</script>
<style lang="less" scoped>
.section-modal {
  ::v-deep .ivu-upload-drag {
    border: 1px dashed #dcdee2;
    &:hover {
      border: 1px dashed #2598e5;
    }
  }
  ::v-deep .el-dialog__body {
    max-height: inherit !important;
  }
}
.video-uploader-layout {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.cover-item {
  height: 50px;
  display: flex;
  position: relative;
  align-items: center;
  text-align: left;
  border-top: solid 1px #e3e3e3;
  border-bottom: solid 1px #e3e3e3;
  overflow: hidden;

  & .left {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  & .middle {
    flex: 1;
    padding-left: 5px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 10;
  }
  & .right {
    width: 24px;
    z-index: 10;
  }

  & .progress {
    position: absolute;
    height: 100%;
    width: 40%;
    background: rgba(104, 125, 219, 0.08);
    transition: 0.3s ease;
    .progress-text {
      position: absolute;
      height: 48px;
      line-height: 48px;
      color: rgba(151, 159, 184, 0.26);
      font-size: 40px;
      right: 8px;
    }
  }
  & .progress.finish {
    background: #47c7654f;
  }
}
.cover-item + .cover-item {
  border-top: none;
}
.uploaded-delete {
  position: absolute;
  right: 4px;
  top: 4px;
}
.uploaded-delete:hover {
  color: #6c91da;
}
.uploader-fixed-size {
  margin-bottom: 10px;
}
.upload-footer {
  margin: 8px 0;
  text-align: center;
}
.imgUrl {
  height: 50px;
  width: 100%;
  border-top: 1px solid #eaedf1ff;
  border-bottom: 1px solid #eaedf1ff;
  padding: 5px 0;
  display: flex;
  line-height: 50px;
  margin-bottom: 20px;
}
#video-style {
  display: none;
}
</style>
