<template>
  <div v-loading="loading" element-loading-text="加载中...">
    <el-table class="elTable" :data="evaList" width="100%">
      <el-table-column label="序号" width="60" align="center">
        <template slot-scope="scope"
          ><div>{{ scope.$index + 1 }}</div></template
        >
      </el-table-column>
      <el-table-column label="用户" align="center">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center;justify-content: center">
            <el-avatar size="small" :src="scope.row.avatar" />
            <span>{{ scope.row.creatorName }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="评分" prop="score" />
      <el-table-column label="反馈" prop="content" />
      <el-table-column label="时间" prop="content">
        <template slot-scope="scope"
          ><div>{{ scope.row.createTime | getTime }}</div></template
        >
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button v-if="isOwner" type="text" @click="delEvaById(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageDiv">
      <el-pagination
        background
        layout="total,prev,pager,next,sizes"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 40, 100]"
        :total="listQuery.total"
        :page-size="10"
        :current-page="listQuery.pageNum + 1"
      />
    </div>
  </div>
</template>

<script>
import course from '../../../../../api/course'
import util from '../../../../../util/util'
export default {
  name: 'evaluation',
  props: {
    isOwner: {
      type: Number
    },
    courseId: {
      type: Number,
      default: 0
    }
  },
  filters: {
    getTime(date) {
      let d = new Date(date)
      return util.dateTime(d)
    }
  },
  data() {
    return {
      evaList: [],
      loading: false,
      listQuery: {
        pageNum: 0,
        pageSize: 10,
        total: 0
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    elMess(msg, type) {
      this.$message({ message: msg, type: type })
    },
    handleSizeChange(size) {
      this.listQuery.pageNum = 0
      this.listQuery.pageSize = size
      this.getList()
    },
    handleCurrentChange(num) {
      this.listQuery.pageNum = num - 1
      this.getList()
    },
    delEvaById(row) {
      this.$confirm('确定要删除此评价', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        course.delEvaById(row.id).then(res => {
          if (res.res) {
            this.elMess('成功', 'success')
            if (this.evaList.length === 1) this.listQuery.pageNum = 0
            this.getList()
          }
        })
      })
    },
    getList() {
      this.loading = true
      course
        .getEvaList(this.courseId, this.listQuery.pageNum, this.listQuery.pageSize)
        .then(res => {
          this.evaList = res.res.data
          this.listQuery.total = res.res.total
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../theme/testPaper';
/deep/ .el-table th,
.el-table tr {
  color: #333;
  background-color: #fafafa !important;
  height: 56px;
}
::v-deep .el-table__row {
  height: 56px;
}
::v-deep .el-table th > .cell {
  font-size: 16px;
  color: #3a4e64;
  font-weight: normal !important;
}
::v-deep .el-table .cell {
  font-size: 14px;
  color: #555555;
}
::v-deep .el-table__expanded-cell {
  font-size: 14px;
  color: #555555;
}
</style>
